<template>
	<div class="h-100" :class="{ sticky: active }">
		<div id="js-header" class="page-header bg-white">
			<b-container>
				<div class="restaurant">
					<div class="restaurant-inner d-flex h-100 align-items-md-center">
						<div class="restaurant-brand">
							<b-skeleton v-if="configLoader" class="mb-2" animation="wave" width="70px" height="70px"></b-skeleton>
							<img v-else :src="logoUrl" alt=""/>
						</div>
						<div class="restaurant-head">
							<b-skeleton v-if="configLoader" class="mb-2" animation="wave" width="200px" height="30px"></b-skeleton>
							<h2 v-else class="restaurant-title h1 mb-0">
								{{ storeName }}
							</h2>
							<div class="restaurant-meta">
								<ul class="list-unstyled d-flex flex-wrap mb-0">
									
									<li class="restaurant-meta_item d-flex align-items-center mb-2 mb-md-0 mr-2 mr-lg-4">
										<div v-if="configLoader">
											<b-skeleton animation="wave" width="200px" height="20px"></b-skeleton>
										</div>
										<div v-else>
											<svg class="icon icon-location mr-1">
												<use xlink:href="#icon-location"></use>
											</svg>
											<span>
												{{ storeAddress }}
											</span>
										</div>
									</li>
									<li class="restaurant-meta_item align-items-center mb-2 mb-md-0 mr-2 mr-lg-4 d-none d-md-flex">
										<div v-if="configLoader">
											<b-skeleton animation="wave" width="200px" height="20px"></b-skeleton>
										</div>
										<div v-else>
											<svg class="icon icon-clock mr-1">
												<use xlink:href="#icon-clock"></use>
											</svg>
											<span> Open {{ storeWorkingHours }} </span>
										</div>
									</li>
									<li
										v-if="true"
										class="restaurant-meta_item align-items-center mb-2 mb-md-0 mr-0 mr-lg-4 d-none d-md-flex"
									>
										<div v-if="configLoader" > 
											<b-skeleton animation="wave" width="200px" height="20px"></b-skeleton>
										</div>
										<div v-else>
											<svg class="icon icon-call mr-1">
												<use xlink:href="#icon-call"></use>
											</svg>
											<a :href="'tel:' + storePhone">
												{{ storePhone }}
											</a>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>

					<div class="restaurant-meta d-md-none">
						<ul class="list-unstyled d-flex mb-0">
							<li class="restaurant-meta_item d-flex align-items-center mb-2 mb-lg-0 mr-2 mr-lg-4">
								<svg class="icon icon-clock mr-1">
									<use xlink:href="#icon-clock"></use>
								</svg>
								<span> Open {{ storeWorkingHours }} </span>
							</li>
							<li class="restaurant-meta_item d-flex align-items-center mb-2 mb-lg-0 mr-0 mr-lg-4">
								<svg class="icon icon-call mr-1">
									<use xlink:href="#icon-call"></use>
								</svg>
								<a :href="'tel:' + storePhone">
									{{ storePhone }}
								</a>
							</li>
						</ul>
					</div>
				</div>
			</b-container>
		</div>

		<div class="pickup bg-white">
			<b-container class="h-100">
				<b-row class="align-items-center h-100">
					<b-col cols="8" lg="9" class="h-100">
						<div class="d-flex align-items-center h-100">
							<div class="pickup-inner"><a href="#" @click="openSettingsModal()">{{ diningOptionName }} - {{serveAt?'at '+serveAt:'NOW'}}</a>
								(30-35
								Min)
							</div>
							<a href="#" @click="openSettingsModal()" class="pickup-edit">
								<svg class="icon icon-edit">
									<use xlink:href="#icon-edit"></use>
								</svg>
							</a>
						</div>
					</b-col>
					<b-col cols="4" lg="3" class="d-flex align-items-center justify-content-end">
						<div class="d-lg-none mr-3">
							<a href="#" class="basket-trigger d-inline-flex mt-1 align-items-center" @click.prevent="toggleSidebar">
								<svg class="icon icon-basket">
									<use xlink:href="#icon-basket"></use>
								</svg>
								<span class="badge">{{ itemsCount }}</span>
							</a>
						</div>
						<!-- <b-button variant="primary" @click="openLoginModal()">Login</b-button> -->
					</b-col>
				</b-row>
			</b-container>
		</div>

		<main class="page-content">
			<slot/>
		</main>

		<ModalDiningOption v-if="diningOptionName"/>
		<LoginModal />
		<RestuarantClosedModal />
	</div>
</template>
<script>
import ModalDiningOption from "../components/ModalDiningOption";
import LoginModal from "../components/LoginModal.vue";
import {mapActions} from 'vuex'
import dayjs from 'dayjs';
import RestuarantClosedModal from "../components/RestaurantClosedModal.vue";

export default {
	name: "MainLayout",
	components: { ModalDiningOption, LoginModal, RestuarantClosedModal },
	data () {
		return {
			active: false,
			config: {
				name: ""
			},
			newDiningOption: "",
			storeOpeningTime:'',
			storeClosingTime:''
		};
	},
	computed: {
		storeName () {
			return this.$store.getters.getStoreName;
		},
		storeWorkingHours () {
			const store = this.$store.getters.getStore;
			const openTime = new Date("1970-01-01T" + store.start_time + "Z").toLocaleTimeString(
				{},
				{ timeZone: "UTC", hour12: true, hour: "numeric", minute: "numeric" }
			);
			const closeTime = new Date("1970-01-01T" + store.close_time + "Z").toLocaleTimeString(
				{},
				{ timeZone: "UTC", hour12: true, hour: "numeric", minute: "numeric" }
			);
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			this.storeOpeningTime = openTime
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			this.storeClosingTime = closeTime
			return `${openTime}${closeTime ? " - " + closeTime : ""}`;
		},
		storeAddress () {
			return this.$store.getters.getStoreAddress;
		},
		logoUrl () {
			return this.$store.getters.getStoreLogo;
		},
		storePhone () {
			return this.$store.getters.getStorePhone;
		},
		diningOptionName () {
			return this.$store.getters.getDiningOptionName;
		},
		itemsCount: function () {
			return this.$store.getters.getItemsCount;
		},
		serveAt: function () {
			const serveAt = this.$store.getters.getServeAt;
			return serveAt? (dayjs(this.$store.getters.getServeAt).format('DD/MM/YYYY HH:mm')): '';
		},
		configLoader(){
			return this.$store.getters.configLoader;
		}
	},
	created () {
		document.title = this.$store.getters.getStoreName.toString().trim()
			? `${this.$store.getters.getStoreName} - Online Ordering`
			: "Online Ordering";
	},
	methods: {
		...mapActions({
			toggleLoginModal:'auth/toggleLoginModal'
		}),
		toggleSidebar () {
			this.$store.commit("toggleSidebar");
		},
		openSettingsModal: function () {
			console.log("openSettingsModal");
			console.log(this.dining_option);
			this.chosenDiningOption = this.dining_option;
			return this.$bvModal.show("modal-order-settings");
		},
		openLoginModal(){
			localStorage.setItem('loginClosed',false)
			this.$store.commit('auth/SET_OPEN_LOGIN_MODAL',true)
			// localStorage.setItem('loginClosed',false)
		},
		isStoreClosed(){
			const currentTime = new Date().toLocaleTimeString(
				{},
				{ timeZone: "UTC", hour12: true, hour: "numeric", minute: "numeric" }
			)
			if(this.storeOpeningTime !== '' || this.storeClosingTime !== ''){
				// getting time period AM or PM
				const timePeriod = currentTime.split(" ")[1]
				const openingTimePeriod = this.storeOpeningTime.split(" ")[1]
				const closingTimePeriod = this.storeClosingTime.split(" ")[1]
				// getting hour and minute of current time
				const currentHour = parseInt(currentTime.split(":")[0])
				const currentMinute = parseInt(currentTime.split(":")[1].split(" ")[0])
				// getting hour and minute of store opening time
				const openingTimeHour = parseInt(this.storeOpeningTime.split(":")[0])
				const openingTimeMinute = parseInt(this.storeOpeningTime.split(":")[1].split(" ")[0])
				// getting hour and minute of store closing time
				const closingTimeHour = parseInt(this.storeClosingTime.split(":")[0])
				const closingTimeMinute = parseInt(this.storeClosingTime.split(":")[1].split(" ")[0])

				// if(timePeriod === 'AM'){
					// check if the resturant opening hours are in the AM and not the closing
						if ( openingTimePeriod === timePeriod && closingTimePeriod !== timePeriod ){
							// check if the current hour is not less then the opening hour
							if(currentHour < openingTimeHour){
								this.$bvModal.show("restaurant-closing-modal")
							}
							// check if the hours are same 
							else if (currentHour === openingTimeHour){
								// check if the minutes is not less 
								if(currentMinute < openingTimeMinute){
									this.$bvModal.show("restaurant-closing-modal")
								}
							}
							else{
								this.$bvModal.hide("restaurant-closing-modal")
							}
						}
						// check if the restaurant closing hours are in the AM and not the opening
						else if ( closingTimePeriod === timePeriod && openingTimePeriod !== timePeriod ){
							// check if the current hour is not past the closing time
							if(currentHour >= closingTimeHour){
								this.$bvModal.show("restaurant-closing-modal")
							}
							else if (currentHour === closingTimeHour){
								// check if the current minute is not past the closing time
								if(currentMinute >= closingTimeMinute){
									this.$bvModal.show('restaurant-closing-modal')
								}
							}
							else{
								this.$bvModal.hide("restaurant-closing-modal")
							}
						}
						// check if the restaurant closing hours and opening hours are both in the AM
						else if( closingTimePeriod === timePeriod && openingTimePeriod === timePeriod ){
							// check if the current hour is past the opening hour and not past the closing hour 
							if( currentHour < openingTimeHour && currentHour > closingTimeHour){
								this.$bvModal.show('restaurant-closing-modal')
							}
							else if( currentHour === openingTimeHour || currentHour === closingTimeHour ){
								// check if the current minute is past closing time and not past closing time
								if( currentMinute < openingTimeMinute && currentMinute > closingTimeMinute){
									this.$bvModal.show('restaurant-closing-modal')
								}
							}
							else{
								this.$bvModal.hide("restaurant-closing-modal")
							}

						}		
						else
						{
							this.$bvModal.show('restaurant-closing-modal')
						}
				// }
				// if(timePeriod === 'PM'){
				// 		// check if the resturant opening hours are in the PM and not the closing
				// 		if ( openingTimePeriod === 'PM' && closingTimePeriod !== 'PM' ){
				// 			// check if the current hour is not less then the opening hour
				// 			if(currentHour < openingTimeHour){
				// 				this.$bvModal.show('restaurant-closing-modal')
				// 			}
				// 			// check if the hours are same 
				// 			else if (currentHour === openingTimeHour){
				// 				// check if the minutes is not less 
				// 				if(currentMinute < openingTimeMinute){
				// 					this.$bvModal.show('restaurant-closing-modal')
				// 				}
				// 			}
				// 			else{
				// 				this.$bvModal.hide("restaurant-closing-modal")
				// 			}
				// 		}
				// 		// check if the restaurant closing hours are in the PM and not the opening
				// 		else if ( closingTimePeriod === 'PM' && openingTimePeriod !== 'PM' ){
				// 			if(currentHour >= closingTimeHour){
				// 				this.$bvModal.show('restaurant-closing-modal')
				// 			}
				// 			else if (currentHour === closingTimeHour){
				// 				if(currentMinute >= closingTimeMinute){
				// 					this.$bvModal.show('restaurant-closing-modal')
				// 				}
				// 			}
				// 			else{
				// 				this.$bvModal.hide("restaurant-closing-modal")
				// 			}
				// 		}
				// 		else if( closingTimePeriod === 'PM' && openingTimePeriod === 'PM' ){
				// 			if( currentHour < openingTimeHour && currentHour > closingTimeHour){
				// 				this.$bvModal.show('restaurant-closing-modal')
				// 			}
				// 			else if( currentHour === openingTimeHour || currentHour === closingTimeHour ){
				// 				if( currentMinute < openingTimeMinute && currentMinute > closingTimeMinute){
				// 					this.$bvModal.show('restaurant-closing-modal')
				// 				}
				// 			}
				// 			else{
				// 				this.$bvModal.hide("restaurant-closing-modal")
				// 			}
				// 		}		
				// 		else{
				// 			this.$bvModal.show('restaurant-closing-modal')
				// 		}
				// }
			}

		}
	},
	mounted () {
		// const forcedLoginModalClosed =  localStorage.getItem('loginClosed')
		// if(forcedLoginModalClosed &&  forcedLoginModalClosed === 'false')
		// 	this.toggleLoginModal(true)
		// else if(!forcedLoginModalClosed)
		// 	this.toggleLoginModal(true)
		window.document.onscroll = () => {
			const navBar = document.getElementById("js-header");
			this.active = window.scrollY > navBar.offsetTop;
		};
		// TODO: will look in again store close functionality
		// setInterval(this.isStoreClosed,1000)
	}
};
</script>
