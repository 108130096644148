<template>
	<b-col>
		<h5 class="mb-3 mb-lg-4">
			Your Order
		</h5>

		<div v-if="items.length === 0">
			<div class="empty text-center">
				<div class="d-flex flex-column justify-content-center h-100">
					<b-img src="../assets/images/sidebar-empty.svg" fluid alt="Fluid image"></b-img>
					<h5>
						<strong>Your cart is empty.</strong>
					</h5>
					<p>
						Please add items to get started.
					</p>
				</div>
			</div>
			<div class="copyright">
				powered by <a href="https://biyopos.com/">Biyo Pos</a>
			</div>
		</div>
		<div v-else-if="items.length > 0" class="order">
			<div class="order-list">
				<div v-for="(item, index) in items" :key="index" class="order-item">
					<div class="order-item-top d-flex justify-content-between">
						<div class="order-quant">
							<span>{{ item.quantity }}</span>
						</div>
						<h6 class="order-title">
							{{ item.name }}
						</h6>
						<div class="order-price ml-auto">{{currency}} {{ countProductPrice(item).toFixed(2) }}</div>
					</div>

					<div class="order-item-body">
						<span
							v-for="(currentModifiers, modifierGroupName) in item.modifiersGroupped || []"
							:key="modifierGroupName"
							class="mb-2"
						>
							<p>
								<strong>{{ modifierGroupName }}</strong>
							</p>
							<p v-for="currentModifier in currentModifiers || []" :key="currentModifier.id" class="mb-2">
								{{ currentModifier.name }}
							</p>
							<p></p>
						</span>
					</div>

					<div class="order-item-settings">
						<a href="#" class="link text-primary" @click="isEditItemOrder(index)">
							<span>Edit</span>
						</a>
						<a href="#" class="link text-danger" @click="openRemovePopup(index)">
							<span>Remove</span>
						</a>
					</div>
				</div>
			</div>

			<div class="order-bottom bg-white">
				<div class="order-total">
					<div class="order-total-tr">
						<div class="order-item-top d-flex justify-content-between">
							<div>
								Subtotal
							</div>
							<div class="order-price">{{currency}} {{ subtotal.toFixed(2) }}</div>
						</div>
					</div>

					<div class="order-total-tr">
						<div class="order-item-top d-flex justify-content-between">
							<div>
								Tax
							</div>
							<div class="order-price">{{currency}} {{ totalTax.toFixed(2) }}</div>
						</div>
					</div>
				</div>

				<div class="order-check d-none d-lg-block">
					<b-button @click="checkout" variant="primary" class="w-100" squared size="lg">
						<strong>Checkout ({{ items.length }})</strong>
						<strong>{{currency}} {{ grand_total.toFixed(2) }}</strong>
					</b-button>
				</div>
			</div>
		</div>

		<div v-if="isRemoveItemOrderShow" class="mask d-flex flex-column justify-content-center">
			<h4 class="mask-title">Remove {{ itemRemoving.name }} from your cart?</h4>
			<div class="mask-settings d-flex justify-content-center">
				<b-button variant="outline-light" text-variant="white" @click="isRemoveItemOrder">NO</b-button>
				<b-button variant="light" text-variant="dark" class="ml-3" @click="removeFromCart()">YES</b-button>
			</div>
		</div>
	</b-col>
</template>

<script>
import sumBy from "lodash/sumBy";

export default {
	name: "Sidebar",
	data () {
		return {
			itemRemoving: null
		};
	},
	props: {
		items: { type: Array, default: () => [] },
		isEditItemOrder: { type: Function, default: null },
		isRemoveItemOrder: { type: Function, default: null },
		removeFromCart: { type: Function, default: null },
		isRemoveItemOrderShow: { type: Boolean, default: false },
		checkout: { type: Function, default: null }
	},
	computed: {
		subtotal () {
			return this.$store.getters.getSubtotal;
		},
		totalTax () {
			return this.$store.getters.getTotalTax;
		},
		grand_total () {
			return this.subtotal + this.totalTax;
		},
		currency () {
			return this.$store.getters.getCurrencySymbol;
		}
	},
	methods: {
		countProductPrice (product) {
			const modTotal = sumBy(product.modifiers, (mod) => mod.price);
			return (product.price + modTotal) * product.quantity;
		},
		openRemovePopup (index) {
			this.itemRemoving = this.items[index];
			this.isRemoveItemOrder(index);
		}
	}
};
</script>

<style lang="scss" scoped></style>
